import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { signin } from 'api/endpoints/auth';
import { auth } from "api/firebase";
import parameters from 'api/parameters';
import Header from "components/Header/Header";
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import Logo from "components/Logo/Logo";
import aiportAccess from 'constants/aiportAccess.json';
import airportStats from "constants/airportStats.json";
import adminAirportTabs from "constants/adminAirportTabs.json";
import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Route, useHistory, useLocation } from "react-router-dom";
import { updateAirport, updateAirports } from 'store/airport/actions';
import { updateAirlines } from 'store/filterFieldsBar/actions';
import { updateUser } from 'store/user/actions';
import { darkerBlue, fadedDarkerBlue, greyishBlue } from "styles/colors";
import TabRoutes from './TabRoutes';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#eeeeee',
    height: "100%",
    [theme.breakpoints.down('sm')]: {
      overflow: "auto"
    }
  },
  tab: {
    padding: 15
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center",
    flexDirection: "column",
    height: "100%"
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  routeTabs:{
    flexGrow: 1
  },
  active: {
    backgroundColor: "red"
  },
  avatar:{
    height: 100,
    width: 100
  },
  tabSection : {
    display: "flex",
    alignItems: "stretch",
    flexDirection: "column",
    overflow: "auto",
    paddingBottom: 10,
    flex: 1
  },
  linearProgress: {
    width: 200
  }
}));

const Dashboard = ({ user, updateUser, updateAirport, updateAirports, updateAirlines, window, airport }) => {
  const classes = useStyles();
  const [loadScreen, changeLoadingScreen] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const history = useHistory()
  const location = useLocation();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const routes = useMemo(() => {
    return TabRoutes.map((prop, key) => {
      return(
        <Route key={key} path={`/dashboard${prop.path}`} component={prop.component} />
      )
    })
  }, [])

  useEffect(() => {
    if(user?.airportCode){
      if(user?.admin && airport?.code){
        history.push(`/dashboard${adminAirportTabs[airport.code][0]}`)
      }else{
        const firstTabAvailable = aiportAccess[user?.airportCode][user?.taxiNumber || user?.guestServices ? "taxi" : "personel"][0]
        history.push(`/dashboard${firstTabAvailable}`)
      }
    }
  }, [user, airport]) // eslint-disable-line react-hooks/exhaustive-deps

  const tabs = useMemo(() => {
    if (!airport?.code || !user?.airportCode) return null;
  
    const generateTab = (key, prop, isCurrentTab) => (
      <ListItem
        key={key}
        onClick={() => history.push(`/dashboard${prop.path}`)}
        style={{
          backgroundColor: isCurrentTab ? fadedDarkerBlue : "inherit",
          cursor: "pointer",
        }}
      >
        <ListItemIcon>
          <prop.icon style={{ color: isCurrentTab ? "white" : darkerBlue }} />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              style={{
                fontSize: "0.875rem",
                color: isCurrentTab ? "white" : darkerBlue,
              }}
            >
              {prop.sidebarName}
            </Typography>
          }
        />
      </ListItem>
    );
  
    return TabRoutes.map((prop, key) => {
      const isCurrentTab = location.pathname === `/dashboard${prop.path}`;
  
      if (user?.admin && adminAirportTabs[airport.code]?.includes(prop.path)) {
        return generateTab(key, prop, isCurrentTab);
      }
  
      const accessLevel = user?.taxiNumber || user?.guestServices ? "taxi" : "personnel";
      if (aiportAccess[user?.airportCode]?.[accessLevel]?.includes(prop.path)) {
        return generateTab(key, prop, isCurrentTab);
      }
  
      return null;
    });
  }, [location, history, airport, user]); // eslint-disable-line react-hooks/exhaustive-deps

  const windowContainer = window !== undefined ? () => window().document.body : undefined;

  const drawerMenu = (
    <div style = {{borderRight: darkerBlue}}>
      <Toolbar style = {{background: darkerBlue}}>
        <Logo/>
      </Toolbar>
      <List style = {{padding: 0}}>
        {tabs}
      </List>
    </div>
  );

  useEffect(() => {
    onAuthStateChanged(auth, async(firebaseUser) => {
      if(firebaseUser) {
        try{
          const getUserInfo = await signin();
          const userInfo = getUserInfo.data.data[0];
          if(userInfo){
            const airlines = await parameters.get("/getAirlines", { params: {
              airport: userInfo.airportCode
            }});
            updateAirport(airportStats.filter((airport) => airport.code === userInfo.airportCode)[0])
            updateAirports(airportStats)
            updateAirlines(airlines.data.data)
            updateUser(userInfo)
          }
          changeLoadingScreen(false)
        }catch(e){
          console.log(e)
          changeLoadingScreen(false)
        }
      }else{
        history.push('/auth')
      }
    });
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  if(loadScreen){
    return(
      <LoadingScreen/>
    )
  }
  return (
    <Box sx={{ display: 'flex', height: "inherit", background: greyishBlue }}>
      <Header drawerOpen = {drawerOpen} handleDrawerToggle = {handleDrawerToggle}/>
      <Box 
        component="nav" 
        sx={{ 
          "@media (min-width: 900px)":{
            width: drawerWidth,
          }
        }}
      >
        <Drawer
          container={windowContainer}
          variant="temporary"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true
          }}
          sx={{
            display: { xs: 'block', sm: 'block', md: 'none' },
            '& .MuiDrawer-paper': { 
              boxSizing: 'border-box', 
              width: drawerWidth,
              border: "none"
            }
          }}
        >
          {drawerMenu}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'none', md: 'block' },
            '& .MuiDrawer-paper': { 
              boxSizing: 'border-box', 
              width: drawerWidth,
              border: "none"
            },
          }}
          open
        >
          {drawerMenu}
        </Drawer>
      </Box>
      <Box className={classes.tabSection} component="main">
        <Toolbar/>
        {routes}
      </Box>
    </Box>
  );
}

const mapDispatchToProps = {
  updateAirport, 
  updateAirports,
  updateAirlines,
  updateUser
}

const mapStateToProps = (state) =>{
  return{
    airport: state.airportReducer.airport,
    user: state.userReducer.user
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)