import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import parameters from 'api/parameters';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

const useStyles = makeStyles({
  chartContainer:{
    marginTop: 20,
    marginBottom: 20
  },
  chartButtonContainer:{
    display: "flex",
    justifyContent: "space-between",
    padding: 10,
    alignItems: "center",
    marginBottom: 20,
    background: "white"
  },
  chartContainers:{
    height: 600,
  },
  loadingContainer:{
    justifyContent: "center",
    display: "flex",
    alignSelf: "center",
    margin: "0 auto",
    height: 600,
    alignItems: "center",
    background: "white",
    width: "100%"
  },
  noResultsContainer:{
    display: "flex",
    alignItem: "center",
    flexDirection: "column",
    height: 600,
    background: "white"
  },
  noMessageResults:{
    fontSize: 12,
    padding: 10
  },
  wordCountTitle:{
    fontSize: 15,
    paddingLeft: 20
  }
});

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#de4444'];


const ChartContainer = (props) => {
  const classes = useStyles();
  const [timeFrame, setTimeFrame] = useState(undefined)
  const [wordCount, setWordCount] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const location = useLocation();
  const axiosSource = axios.CancelToken.source();
  const { airline, airport, flightDate, title } = props;

  let count = 0;
  for(let dataFiled in wordCount){
    wordCount[dataFiled]["color"] = COLORS[count]
    count ++;
    if (count > COLORS.length - 1){
      count = 0;
    }
  }

  const getUrl = () => {
    let url = "/count"
    switch (true) {
      case location.pathname.indexOf("disruption") > 0:
        url += "Disruptions"
        break;
      case location.pathname.indexOf("aircraft") > 0:
        url += "Aircrafts"
        break;
      case location.pathname.indexOf("ground") > 0:
        url += "GroundOps"
        break;
      case location.pathname.indexOf("map") > 0:
        url += "AirportLocations"
        break;
      case location.pathname.indexOf("cargo") > 0:
        url += "CargoAircraftCarriers"
        break;
      default:
        url += "Disruptions"
        break;
    }
    return url
  }

  const getWordCount = async() => {
    try {
      let wordCountRes = await parameters.post(getUrl(), { 
        startTimestamp: timeFrame ? flightDate.getTime()/1000 - timeFrame : 0,
        endTimestamp: flightDate.getTime()/1000,
        airport: airport.code,
        airline: airline
      }, {cancelToken: axiosSource.token});
      return wordCountRes.data.data;
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    let active = true  
    const loadWordCount = async() => {
      setIsLoading(true)
      const result = await getWordCount()
      if (!active) { return }
      const filteredResult = result.filter((item) => {
        return item.count > 0
      });
      setWordCount(filteredResult)
      setIsLoading(false)
    }
    loadWordCount()
    return () => { active = false }
    // eslint-disable-next-line
  }, [location, flightDate, airport, airline, timeFrame])
  
  return (
    <div>
      {isLoading ?  
        <div className={classes.loadingContainer}> 
          <CircularProgress/>
        </div> 
        :
        <Grid container justify="space-around" spacing={1}>
          <Grid item xs = {12} sm = {12} md = {6} className={classes.chartContainers}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={350}
                height={window.innerHeight > 800 ? 700 : 500}
                data={wordCount}
                margin={{
                  top: 30,
                  right: 40,
                  left: 30,
                  bottom: 30,
                }}
                barSize={20}
              >
                <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
                <YAxis />
                <Tooltip />
                <Legend verticalAlign="top" height={36}/>
                <CartesianGrid strokeDasharray="3 3" />
                <Bar dataKey="count" fill="#3f51b5" background={{ fill: '#eee' }} />
              </BarChart>
            </ResponsiveContainer>
          </Grid>

          <Grid item xs = {12} sm = {12} md = {6} className={classes.chartContainers}>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart 
                width={370} 
                height={wordCount.length < 20 ? 400 : 600} 
                margin={{
                  top: 30,
                  right: 20,
                  left: 20,
                }}
              >
                <Pie
                  dataKey="count"
                  data={wordCount}
                  cx="50%"
                  cy={
                    wordCount.length < 20 ? "50%" : 
                    window.innerWidth < 830 && wordCount.length > 20 ? "80%" : 
                    "65%"}
                  outerRadius={80}
                >
                  {wordCount.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend verticalAlign="top" height={10}/>
              </PieChart>
            </ResponsiveContainer>
          </Grid>
        </Grid>
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return{
    airport: state.airportReducer.airport,
    flightDate: state.filterFieldsBarReducer.flightDate,
    airline: state.filterFieldsBarReducer.airline
  }
}

export default connect(mapStateToProps)(ChartContainer)