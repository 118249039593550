import React, { useState } from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import StatisticCard from 'components/StatisticCard/StatisticCard';
import Chart from 'react-apexcharts';
import { Line } from 'react-chartjs-2';
import { lightGrey } from "styles/colors";
import RevenueReportGraph from "components/RevenueReportGraph/RevenueReportGraph";
import InvoicesTable from 'components/InvoicesTable/InvoicesTable';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import IconButton from '@material-ui/core/IconButton';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const generateChartData = () => {
  const labels = ['Oct 1', 'Oct 2', 'Oct 3', 'Oct 4', 'Oct 5', 'Oct 6', 'Oct 7']; // 7-day labels

  return {
    labels,
    datasets: [
      {
        label: 'The Parking Spot',
        data: [150, 200, 250, 300, 270, 320, 350],
        borderColor: '#FFA500',
        backgroundColor: '#FFA500',
      },
      {
        label: 'Doubletree',
        data: [100, 120, 140, 150, 180, 210, 220],
        borderColor: '#FFD700',
        backgroundColor: '#FFD700',
      },
      {
        label: 'Star Cab',
        data: [50, 60, 90, 70, 80, 100, 120],
        borderColor: '#FF8C00',
        backgroundColor: '#FF8C00',
      },
      {
        label: 'Hertz',
        data: [30, 40, 50, 60, 50, 70, 80],
        borderColor: '#FF4500',
        backgroundColor: '#FF4500',
      },
    ]
  };
};

const chartOptions = {
  chart: {
    type: 'bar',
    stacked: true,
    toolbar: {
      show: true
    }
  },
  plotOptions: {
    bar: {
      horizontal: false,
    },
  },
  xaxis: {
    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  },
  yaxis: {
    title: {
      text: 'Number of Surveys',
    }
  },
  legend: {
    position: 'bottom',
    offsetY: 0
  },
  fill: {
    opacity: 1
  },
  dataLabels: {
    enabled: false
  },
  colors: ['#FFA500', '#FFD700', '#FF8C00', '#FF4500']
};

const chartSeries = [
  {
    name: 'The Parking Spot',
    data: [50, 80, 60, 100, 120, 150, 200, 170, 190, 220, 240, 210]
  },
  {
    name: 'Doubletree',
    data: [30, 50, 40, 60, 80, 100, 120, 110, 130, 140, 150, 130]
  },
  {
    name: 'Star Cab',
    data: [10, 20, 15, 30, 40, 60, 80, 70, 50, 60, 70, 50]
  },
  {
    name: 'Hertz',
    data: [5, 10, 8, 15, 20, 25, 30, 25, 35, 40, 45, 30]
  }
];

const FeedbackSurveysTab = () => {
  const lineChartData = generateChartData();
  const [liked, setLiked] = useState(null);

  const handleThumbUpClick = () => {
    setLiked(liked === 'up' ? null : 'up');
  };

  const handleThumbDownClick = () => {
    setLiked(liked === 'down' ? null : 'down');
  };

  return (
    <Grid container spacing={3} style={{ padding: 20 }}>
      <Grid item xs={12}>
        <Paper elevation={3} style={{ padding: 20 }}>
          <Typography variant="h4" align="center" sx={{color: lightGrey}}>
            Invoice Reports
          </Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} md={6} spacing={3}>
        <StatisticCard
          title="Invoices Created This Month"
          count={45}
          textColor={lightGrey}
          backgroundColor="#fff"
          backgroundAfterColor="#FFD700"
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} spacing={3}>
        <StatisticCard
          title="Invoices Created All-Time"
          count={23595}
          textColor={lightGrey}
          backgroundColor="#fff"
          backgroundAfterColor="#FF4500"
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <Paper elevation={3} style={{ padding: 20 }}>
          <Typography variant="h6" gutterBottom sx={{color: lightGrey}}>
            Company Invoices
          </Typography>
          <Chart options={chartOptions} series={chartSeries} type="bar" height={400} />
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <Paper elevation={3} style={{ padding: 20 }}>
          <Typography variant="h6" gutterBottom sx={{color: lightGrey}}>
            Invoices Created Over Time
          </Typography>
          <Line data={lineChartData} />
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <RevenueReportGraph/>
      </Grid>

      <Grid item xs={12}>
        <InvoicesTable/>
      </Grid>

      <Grid item xs={12}>
        <Paper elevation={3} style={{ padding: 20 }}>
          <Typography variant="h6">
            AI Analysis
          </Typography>
          <Typography variant="subtitle2" gutterBottom sx={{pb: 1}}>
            (from the data gathered in the last 90 days)
          </Typography>
          <Typography variant="body1" gutterBottom>
            Hertz vehicles have struggled with payments more than other providers. Sam, in particular, has accounted for 90% of failed payments. Please reach out to him to contact NTTA and update the payment connected to his toll tag.
          </Typography>

          <Box sx={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
            <Typography variant="subtitle2">
              Did this help solve the problem?
            </Typography>
            <IconButton onClick={handleThumbUpClick} color={liked === 'up' ? 'primary' : 'default'}>
              <ThumbUpIcon />
            </IconButton>
            <IconButton onClick={handleThumbDownClick} color={liked === 'down' ? 'primary' : 'default'}>
              <ThumbDownIcon />
            </IconButton>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default FeedbackSurveysTab;
