import Grid from '@mui/material/Grid';
import ChartContainer from 'components/ChartContainer/ChartContainer';
import FlightOperationPhrases from 'components/FlightOperationPhrases/FlightOperationPhrases';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import StatisticCard from 'components/StatisticCard/StatisticCard';
import { Box } from '@mui/material';

const DisruptionsTab = () => {
  useEffect(() => {
    ReactGA.pageview('/disruptions');
  }, [])

  return (
    <Box sx={{margin: 3}}>
      <Grid container>
        <Grid item xs={12} sx={{mb: 2}}>
          <StatisticCard
            title="Ground Transportation Disruptions"
            count={4500}
            backgroundColor="#3F51B5"
            backgroundAfterColor="#00bcd4"
          />
        </Grid>

        <Grid item xs={12}>
          <FlightOperationPhrases showDisruptionPhrases />
        </Grid>

        <Grid item xs={12}>
          <ChartContainer/>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DisruptionsTab;