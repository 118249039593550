import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { UnDelayLogoDarkRed } from 'styles/colors'
import Modal from 'components/Modal/Modal';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';
import PizZip from 'pizzip';
import JSZip from 'jszip';
import { saveMonthlyReport } from "api/endpoints/reportingAutomation";

const StyledPaper = styled(Paper)(() => ({
  padding: '20px',
  textAlign: 'center'
}));

const CreateInvoiceCard = ({ monthlyReport, csvData, month, year }) => {
    const [sendEmailsModalOpen, setEmailsModalOpen] = useState(false);

    const emailDriversInvoices = () => {
        
    }

    const handleSendEmailsModalOpen = () => {
        setEmailsModalOpen(true)
    }

    const loadFile = async (url) => {
        return fetch(url)
            .then(response => response.arrayBuffer())
            .then(buffer => new Uint8Array(buffer));
    };
    
    const handleDownloadInvoices = async () => {
        if(!monthlyReport.length) return alert("Please upload csv to generate monthly report.")
        const templatePath = '/docs/InvoiceTemplate.docx';
        const content = await loadFile(templatePath);
        const zip = new JSZip();
    
        monthlyReport.forEach((entry, index) => {
            const zipContent = new PizZip(content);
            const doc = new Docxtemplater(zipContent, {
                delimiters: { start: '«', end: '»' },
                linebreaks: true
            });
    
            const generalData = {
                Name: entry['Name'] ?? "Name",
                Address: entry['Address'] ?? "Address",
                City: entry['City'] ?? "City",
                State: entry['State'] ?? "State",
                Zip: entry["Zip"] ?? "Zip",
                Invoice_Date: entry['Trip Fees Ending'].replace(/\//g, '') ?? "Invoice Date",
                Invoice_: entry['Invoice #'] ?? "Invoice #",
                Data_Source: entry['Data Source'] ?? "Data Source",
                Company_Name: entry['Company Name'] ?? "Company Name",
                Card_Number: entry['Card Number'] ?? "Card Number",
                Toll_Tag_Type: entry['Toll Tag Type'] ?? "Toll Tag Type",
                Trip_Fee: entry['Trip Fee'] ?? "Trip Fee",
                Trip_Fees_Beginning: entry['Trip Fees Beginning'] ?? "Trip Fees Beginning",
                Trip_Fees_Ending: entry['Trip Fees Ending'] ?? "Trip Fees Ending",
                Total_Trips: entry['Total Trips'] ?? "Total Trips",
                Total_Amount: entry['Total Amount'] ?? "Total Amount",
                Date_Due: entry[' Date Due '] ?? "Date Due"
            };

            let fullTableString = "";
    
            if(csvData){
                const entries = csvData.filter(task => task[Object.keys(task)[0]] === entry['Card Number']);
                const tableString = entries.map(task => (
                    `${task[Object.keys(task)[0]]} | ${task['Read At']} | ${task['Plaza']} | ${task['License Plate State']} | ${task['License Plate Number']}`
                )).join('\n');            
                
                const tableHeaders = `Card Number | Read At | Plaza | License Plate State | License Plate Number \n`;
                fullTableString += `${tableHeaders}\n${tableString}`;  
            }
          
    
            doc.setData({
                ...generalData,
                TripDetailsTable: fullTableString
            });
    
            try {
                doc.render();
            } catch (error) {
                console.error('Error rendering document:', error);
                return;
            }
    
            const output = doc.getZip().generate({ type: 'blob' });
            const fileName = `GT${month}${year}-${entry['Invoice #']}-${index + 1}.docx`;
    
            zip.file(fileName, output); 
        });
    
        zip.generateAsync({ type: 'blob' }).then((zipFile) => {
            saveAs(zipFile, `Invoices_${month}${year}.zip`);
        }).catch(error => {
            console.error('Error generating zip file:', error);
        });

        await saveMonthlyReport(monthlyReport) 
    };

    return (
        <StyledPaper sx={{mt: 3}} elevation={3}>
            <Modal
                popupTitle = "Are you sure you want to send invoices to all drivers in the report?" 
                popupDescription = "You will send out a total of 31 invoice PDFs."
                agreeAction={emailDriversInvoices}
                open={sendEmailsModalOpen}
                setOpen={setEmailsModalOpen}
            />
            <PictureAsPdfIcon style={{ fontSize: 60, color: UnDelayLogoDarkRed }} />
            <Typography variant="h6">
                {monthlyReport.length} Invoices
            </Typography>
            <Box
                display="flex"
                justifyContent="center"
                mt={2}
                sx={{
                    flexDirection: {
                        xs: 'column',
                        sm: 'row'   
                    },
                    alignItems: 'center',
                }}
                >
                {/* <Button variant="outlined" onClick={handleSendEmailsModalOpen} sx={{ mx: { xs: 0, sm: 1 }, mb: { xs: 2, sm: 0 } }}>
                    Send to Invoices to All Drivers
                </Button> */}
                <Button variant="outlined" onClick={handleDownloadInvoices} sx={{ mx: { xs: 0, sm: 1 } }}>
                    Download Invoices
                </Button>
            </Box>
        </StyledPaper>
    );
};

export default CreateInvoiceCard;